import React, { useState,useEffect } from 'react';
import "../style/Home.css";
import { Row, Col } from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
    });
    const [submitting, setSubmitting] = useState(false);
    useEffect(() => {
        if (window.location.hash === '#contact') {
          const contactSection = document.getElementById('newcontact');
          if (contactSection) {
            window.scrollTo({
              top: contactSection.offsetTop+1100,
              behavior: 'smooth',
            });
            // contactSection.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    const emailBody = `
    <div style="background-color: #f5f5f5; padding: 20px; font-family: Arial, sans-serif;">
      <h4 style="color: #333; font-size: 18px; margin-bottom: 10px;">Enquirer’s Information</h4>
      <div style="margin-bottom: 10px;">
        <strong style="color: #555;">Name:</strong> ${formData.name}
      </div>
      <div style="margin-bottom: 10px;">
        <strong style="color: #555;">Phone:</strong> ${formData.mobile}
      </div>
    </div>
  `;
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);
        axios
            .post('https://api.honc.io/admin/chatbot/send-email', {
                to: 'info@door.pe',
                subject: 'Enquirer’s Information',
                body: emailBody,
            })
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Your response has been successfully submitted. Our team will be in touch soon.',
                    timer: 3000
                });
                setFormData({ name: '', mobile: '' });
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <div className="d-flex" id="newcontact">
            <div className="contact-bg">
                <section className="container address">
                    <div className="col-lg-6 col-md-6">
                        <h3 className="address-text">Address</h3>
                        <p className="address-details">
                            Off #31, FBF Commercial<br />
                            Complex, Al Murtafah Street,<br />
                            Al’Seeb, Sultanate of Oman
                        </p>
                        <div className="d-flex phone-img">
                            <img src="./images/phone.png" className="phone-icon" alt="Phone" />
                            <p className="mail-text">968 9188 9194</p>
                        </div>
                        <div className="d-flex email-img">
                            <img src="./images/email.png" className="email-icon" alt="Email" />
                            <p className="mail-text">info@door.pe</p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} method="post">
                        <div className="col-lg-6 col-md-6">
                            <h3 className="address-text">Contact Us</h3>
                            <div className="form-group">
                                <label className="phone-text">Name</label>
                                <input
                                    type="text"
                                    className="form-control name-form"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder="Enter your Name"
                                    required
                                />
                            </div>
                            <div className="form-group mobile-number-form">
                                <label className="phone-text">Mobile Number</label>
                                <input
                                    type="tel"
                                    className="form-control mobile-form"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/\D/g, '');
                                        handleInputChange({ target: { name: 'mobile', value: numericValue } });
                                    }}
                                    placeholder="Enter your Mobile Number"
                                    required
                                />
                            </div>
                        </div>
                        <div >
                            <button type="submit" className=" submit-btn" disabled={submitting}>
                                {submitting ? "Submitting..." : "Submit"}
                            </button>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    );
};

export default Contact;
