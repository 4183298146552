import React from 'react'
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from 'react-router-dom';
import "../style/Home.css"
import { Button } from 'react-bootstrap';


const TermsandConditions = () => {
  const button5 = useNavigate()
  const navigate5 = () => {
    button5('/')
  }

  return (


    <section style={{ backgroundColor: "white" }}>

      <Container fluid className="p-5">
        <img src="./images/backbutton.png" alt="" className='arrow-img' onClick={navigate5}></img>
        {/* <Button onClick={navigate5}>Back</Button> */}

        <h1 style={{ color: "black", marginTop: "1%" }} className='privacypolicy2'>Terms of Use  </h1>
        <h6 style={{ color: "black", marginTop: "2%" }} className='encourage'>The Terms of use have been updated on December 1, 2024.</h6>
        <h3 style={{ marginTop: "30px", color: "black" }} className='encourage' >AGREEMENT TO OUR LEGAL TERMS</h3>
        <p style={{ marginTop: "8px", color: "black" }} className='encourage' >We are Cultural Links Technology LLC ("Company," "we," "us," "our"), a company registered in Oman at Off #31, FBF Commercial Complex, Al Murtafah Street, Al’Seeb, Sultanate of Oman.</p>
        <p style={{ marginTop: "8px", color: "black" }} className='encourage' >We operate the mobile application ‘DoorPe’ (the "App"), as well as any other related products and services that refer or link to these legal terms (the "Legal Terms") (collectively, the "Services").</p>
        <p style={{ marginTop: "8px", color: "black" }} className='encourage' >DoorPe is your go-to platform for creating stronger connections within your neighbourhood. Whether you’re a new arrival or a long-time local, DoorPe helps you engage with neighbours, local businesses, and home-based entrepreneurs, making it easy to form lasting relationships.</p>
        <p style={{ marginTop: "8px", color: "black" }} className='encourage' >You can contact us by email at info@door.pe or visit us at our office at Off #31, FBF Commercial Complex, Al Murtafah Street, Al’Seeb, Sultanate of Oman.</p>
        <p style={{ marginTop: "8px", color: "black" }} className='encourage' >By entering, connecting, using or creating an account on this website and its associated applications on iPhone, iPad, Android, and any mobile application (hereinafter collectively referred to as the "Platform") including without limitation to any services offered on the Platform, you acknowledge that you have read and understood the following Terms and Conditions (collectively the  "Terms"), including these Terms, terms of our Privacy Policy available at www.door.pe and you agree to be bound by them and to comply with all the applicable laws and regulations regarding your use of the Platform.  You acknowledge that these Terms constitute a binding and enforceable legal contract between yourself and Cultural Links Technology LLC (hereinafter referred to as "DoorPe", "Company", "we", "us", "our") with reference to the use of this Platform. Please read these terms before ordering any items from our application.</p>
        <p style={{ marginTop: "8px", color: "black" }} className='encourage' >You become a user of the Platform ("User" or "you"), by entering, using, browsing or registering on the Platform. As a User, you agree and undertake to comply with the provisions of these Terms.</p>
        <p style={{ marginTop: "8px", color: "black" }} className='encourage' >You are responsible for making all arrangements necessary for you to have access to the Platform. You are also responsible for ensuring that all persons who access the Platform through your Internet connection are aware of these Terms and that they comply with them. By viewing, using, accessing, browsing, or submitting any content or material on the Platform, you agree to these Terms as a binding legal agreement, without limitation or qualification. The term "you" or "You" shall include any person or entity, who views, uses, accesses, browses or submits any content or material to the Platform.</p>
        <p style={{ marginTop: "8px", color: "black" }} className='encourage' >If you do not agree to these Terms, then you may not use the Platform. DoorPe reserves the right to modify these Terms at any time without prior notice. You agree that each visit you make to the Platform shall be subject to the Terms as displayed, and your continued use of the Platform now or following the modifications in these Terms confirms that you have read, accepted, and agreed to be bound by such modifications.</p>
        <Accordion className='rounded-2' >
          <Accordion.Item eventKey="0" className='mt-5'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  >JOINING THE NEIGHBORHOOD</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <div>
                <p className='collect1 fw-bold'> a) Eligibility</p>
                <p className='collect1'>You may use our Services only if you can legally form a binding contract with DoorPe, and only in compliance with these Terms and all applicable laws. You can’t use the Services if:</p>
                <ul>
                  <li className='collect1'>You are not a resident of Oman and are under 15 years old, (or do not meet applicable age requirements to use social media services in the jurisdiction of residence). All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Services.</li>
                  <li className='collect1'>We previously disabled your Account for violations of these Terms or other of our Supplemental Terms or policies; </li>
                  <li className='collect1'>Our Services are not directed to your geographic territory; or </li>
                  <li className='collect1'>You are otherwise prohibited by applicable laws from accessing or receiving our Services. We reserve the right to refuse registration for, access to, or use of our Services by any person or household at any time and for any reason.</li>
                </ul>
                <p className='collect1'>If you believe that a user in DoorPe does not meet the above mentioned eligibility requirements, you may report your concerns and the specific account via our Help Centre</p>
              </div>
              <div>
                <p className='collect1 fw-bold'> b) Becoming a neighbour</p>
                <p className='collect1'>When you register to become a neighbour, you must provide us with accurate and complete information. We reserve the right to reject and terminate any registrations submitted with false or inaccurate registration information, or otherwise submitted in violation of these Terms.
                </p>
              </div>
              <div>
                <p className='collect1 fw-bold'> c) Account Types and Access</p>
                <p className='collect1'>Users may create, operate, maintain, or otherwise use an account on DoorPe for personal and/or business use. If you use our Business Services, which include but are not limited to creating or claiming a page that advertises or contains other information about your business (a “Business Page”), then you agree to our <span style={{ textDecoration: 'underline' }}>Business Services Terms</span>,which are incorporated into these Terms. You are responsible for maintaining the security of your Account login information and you must keep such login information confidential. You are responsible for all activity that takes place under your Account. You should notify us immediately if you suspect or become aware of any unauthorized use of or access to your Account.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >USER TRANSACTIONS</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <div>
                <p className='collect1 fw-bold'> a) Products and services offered by users/neighbours</p>
                <p className='collect1'>You, and not DoorPe, are responsible for your own decisions and actions on the Services. DoorPe does not own or sell any of the products or services listed by neighbours on the Services (such as in “For Sale & Free” or on Business Pages), so any actual contract formed is between the User making an offer and the user seeking to purchase or otherwise acquire those goods or services.
                </p>
              </div>
              <div>
                <p className='collect1 fw-bold'> b) Engaging with other users</p>
                <p className='collect1'>DoorPe does not interview, run background checks on, monitor, supervise, direct or control uers. In addition, the use of DoorPe to find a service via search, Business Pages, or any similar feature, does not make us an employer, placement agency, representative, or agent of or for you or any other user or service provider, and you acknowledge and agree that no such relationship is intended or created by these Terms or your use of the Services. If you and another user decide to work together, the two of you, and not DoorPe, are solely responsible for complying with any applicable laws, such as tax and employment laws. Users are not independent contractors, employees, joint venturers, franchisees, or service providers to or for DoorPe.
                </p>
              </div>
              <div>
                <p className='collect1 fw-bold'> c) Prohibited Transactions. </p>
                <p className='collect1'>Except through DoorPe’s Services and in accordance with DoorPe’s guidelines, you may not use the Services to solicit, advertise for, or contact in any form, users for any employment or other commercial purpose. You may not use the Services to collect the contact information or other personal information of neighbours, by electronic means or otherwise, without the neighbour’s explicit consent or our prior written consent.
                </p>
              </div>
              <p className='collect1'>By accepting the Agreement you grant DoorPe permission to use your name, profile picture and information about your actions with ads and sponsored content within the area: You give us permission to use your name and profile picture and information about actions that you have taken on DoorPe to or in connection with ads, offers and other sponsored content that we display across our services, without any compensation to you.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >NOTIFICATION PREFERENCES</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <ul>
                <li className='collect1'>By default, you will receive notifications from DoorPe related to your use of and interactions with the Services within the Services, to the email address to which your Account is linked, and on your device or browser. You may also opt in to other kinds of notifications. You can change your preferences about receiving messages from DoorPe. You can use these preference settings to unsubscribe to specific notification types by email or push notification.</li>
                <li className='collect1'>DoorPe provides a text message service that provides users with notifications relevant to their neighborhood, Account, or other Services, such as urgent alerts. By giving DoorPe your phone number, you are consenting to receiving such text notifications</li>
                <li className='collect1'>You can use our Services to send individuals who are not users invitations, such as by email, or text message. You acknowledge that these Invitations, which we may help to facilitate through our Services, are sent by you and not by DoorPe, and Invitations sent to neighbors not personally selected by you are sent by DoorPe on your behalf.</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >CHANGES TO OUR SERVICE</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>We may offer additional Services and product features, or add, change, or discontinue our existing Services and product features at any time and in any region, with or without notice to you. If you are dissatisfied, your sole remedy is to stop using our Services or the affected feature.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >CONTENT</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the content posted. We reserve the right to remove any content from our site for any reason.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className="intellectual property privacy-header">PRICING & PAYMENT TERMS</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>DoorPe does not provide any payment service either to you or to third parties. All online payments are processed via your own issuer / payment service provider and the payment service providers in partnership with DoorPe.</p>
              <p className='collect1'>Prices quoted on the Platform shall be displayed in the applicable country's national currency and subject to applicable tax. You accept that prices and offers on the Platform may vary from the prices and offers offered by our services  elsewhere (including their own websites, mobile applications, or at their brick-and-mortar outlets). The way we display the prices of our services  may vary depending on the location, type of service, segment of vehicle, and, subject to applicable laws, the prices reflected on our Platform. Prices and additional charges indicated on the Platform are as at the time of each booking and may be subject to change later. </p>
              <p className='collect1'>DoorPe reserves the right to charge fees  (including but not limited to service fees)  for our services as we deem necessary for our business.  The breakdown of the prices and additional charges (including but not limited to tax or Service fees) are displayed before checkout. When you place an Order, you agree to all amounts, additional charges and the final ‘Total' amount which is displayed to you.</p>
              <h6 className='collect1'>Payment Methods</h6>
              <p className='collect1'>When using your Account as payment instrument you may choose from different payment methods as available and indicated on the Platform.</p>
              <p className='collect1'>DoorPe reserves the right to offer additional payment methods and/or remove existing payment methods at any time at its sole discretion. If you choose to pay using an online payment method, the payment shall be processed by our third party payment service provider(s). With your consent, your credit card / payment information will be stored with our third party payment service provider(s) for future Orders. DoorPe does not store your credit card or payment information.</p>
              <p className='collect1'>You must ensure that you have sufficient funds on your credit and debit card to fulfill payment of a booking. DoorPe takes responsibility for payments made on our Platform including refunds, chargebacks, cancellations and dispute resolution, provided if reasonable and justifiable and in accordance with these Terms</p>
              <p className='collect1'>At the time of checkout, please read the terms and conditions carefully before using any payment method.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'>TERMINATION</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>DoorPe has the right to terminate, suspend or delete your Account and your access to or use of the Platform, including any delivery service we provide to you in respect of an Order, for any reason, including, without limitation, if DoorPe, in its sole discretion, considers your use to be unacceptable, or in the event of any breach by you of these Terms. Where appropriate, DoorPe will give you the opportunity to state your position, before any termination, suspension or deletion of your account.</p>
            </Accordion.Body>


          </Accordion.Item>
          <Accordion.Item eventKey="8" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >INTELLECTUAL PROPERTY</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'> All trademarks, logos, images, and service marks, including these Terms as displayed on the Platform or in our marketing material are the Intellectual Property of DoorPe and/or third parties who have authorised us with the right to use such proprietary rights (collectively the "Intellectual Property"). You may not use, copy, reproduce, republish, upload, post, transmit, distribute, or modify these Intellectual Property in any way without our prior express written consent. The use of our Intellectual Property rights, including but not limited to the DoorPe’s trademarks and copyrights to the DoorPe logo on any other website not approved by us is strictly prohibited. DoorPe will aggressively enforce its Intellectual Property rights to the fullest extent of the laws, including criminal prosecution. All rights are reserved.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >APPLICABLE LAW</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>These Terms shall be governed by and construed in accordance with the laws of the Sultanate of Oman. Disputes arising in connection with these Platform Terms (including non-contractual disputes) shall be subject to the exclusive jurisdiction of the courts of the Sultanate of Oman.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >REFUND POLICY</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>At DoorPe, we aim to provide a satisfactory experience to all our customers who use our services. Service cancellation refund will be processed to the customer through their respective mode of payment of the service within 48hrs of cancellation request received.
                Please review the following policy regarding refunds:
              </p>
              <p className='collect1'>DoorPe shall not be liable for any delays or failures in performance resulting from acts beyond its reasonable control including, without limitation, acts of God, acts of war or terrorism, pandemic or epidemic, shortage of supply, breakdowns or malfunctions. Considering the act of God events, you have the option either to reschedule the session or you will be entitled to obtain a refund.
              </p>
              <div>
                <p className='collect1 fw-bold'> a) Refund Eligibility:</p>
                <p className='collect1'>Service Not Rendered: If the service was not provided or completed due to circumstances beyond the customer's control or due to an error on the part of the service provider, a refund may be warranted.
                </p>
                <p className='collect1'>Cancellation Before Service: If the customer cancels the service appointment within a reasonable timeframe before the scheduled service and meets any cancellation policy requirements, they may be eligible for a refund.
                </p>
                <p className='collect1'>Technical Issues or Service Interruptions: If there are technical issues or service interruptions that prevent the completion of the service, the customer may be eligible for a refund.
                </p>
                <p className='collect1'>Accidental Duplicate Service Bookings: If the customer accidentally books the same service multiple times and notifies the service provider promptly, they may be eligible for a refund for the duplicate booking.
                </p>
              </div>
              <div>
                <p className='collect1 fw-bold'> b) Refund Process:</p>
                <p className='collect1'>To request a refund for a service, customers can also contact our customer support team at support@door.pe  with their details and reason for the refund request. Refund requests will be reviewed and processed within 7-14 business days. Approved refunds will be issued to the original payment method used for the service purchase.
                </p>
              </div>
              <div>
                <p className='collect1 fw-bold'> c) Modifications to the Refund Policy:</p>
                <p className='collect1'>DoorPe reserves the right to modify or update this refund policy at any time. Any changes will be updated on our app or website.
                </p>

              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >CANCELLATION POLICY</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>We understand that circumstances may arise where you need to cancel your booked service.
              </p>
              <p className='collect1'>If the Customer is eligible for a refund for a cancelled order subject to the provisions of this T&Cs, the order value paid by the customer will be refunded to the customer's DoorPe Credit, unless the customer requests otherwise, and in the latter case, the refund will be made only to the original payment method used for making the order.
              </p>
              <p className='collect1'>It can take anywhere from 7 - 14 business days (or even up to 30 days for overseas banks) for your refund to be processed and reflected in your bank account, in consideration of the time and procedures required from DoorPe and/ or the Credit Card Company and/ or the Bank.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >FUNCTIONALITY</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>The platform may be under constant upgrades, and some functions and features may not be fully operational. In case of any typographical errors, inaccuracies or omissions on our website or application related to products, services, promotions, offers and availability of products and/or services. We reserve the right to correct such errors.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >LIMITATION OF LIABILITY</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>To the extent permitted by law, DoorPe provides this Platform and content on an "as-is" and "as available" basis and we make no representation or warranty of any kind, express or implied, regarding the content or availability of this Platform, or that it will be timely or error-free, that defects will be corrected, or that the Platform or server that makes it available are free of viruses or other harmful components. Neither DoorPe nor its directors, officers, agents, employees or contractors shall have any liability for any direct, indirect, special or consequential losses or damages (including without limitation, damages for loss of business or loss of profits), arising in contract, tort or otherwise from the use or inability to use this Platform. Nothing in these terms shall exclude or limit liability for death or personal injury resulting from our negligence or that of our agents or employees.
              </p>
              <p className='collect1'>Merchants are solely responsible for the preparation, condition and quality of goods sold to you. In cases of Merchant Delivery, Merchants are responsible for delivery of the goods and/or Orders. DoorPe shall not be responsible or liable for any loss, damage, expense, cost or fees arising from your contractual relationship with the Merchant.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="14" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >INDEMNITY
            </h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>You agree to indemnify and hold DoorPe, its parents, subsidiaries and affiliates, agents, officers, directors, or other employees harmless from any claim, demand, or damage (whether direct, indirect, or consequential), including reasonable attorneys' fees, made by anyone in connection with your use of the Platform, with your submissions, with any alleged infringement of intellectual property or other right of any person or entity relating to the Platform.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="15" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >AMENDMENTS
            </h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>DoorPe may amend these Terms at any time in its sole discretion, and the amended Terms shall be effective immediately upon posting, subject to applicable laws. It is your responsibility to check the Terms regularly. If you do not agree with the amended Terms, whether in whole or in part, you must stop using the Platform and/or any of its services immediately.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="16" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >DISCLAIMER</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>The information, content, products, services, and materials available through the application (whether provided by DoorPe , you, other users or other affiliates/third parties), including without limitation, goods and services orders, submissions, text, photos, graphics, audio files, video, and links, are provided "as is" and "as available" without warranties of any kind, either express or implied. To the maximum extent permitted by law, DoorPe disclaims all representations and warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, title, noninfringement, freedom from computer virus, and implied warranties arising from course of dealing or course of performance.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="17" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >CONTACT US</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>If you have any questions about these Terms, please contact us at <span style={{ textDecoration: 'underline', color: 'blue' }}>info@door.pe</span>
                <br></br>
                For customer support, you may reach out to us via the Help & Support Center available on the Platform.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  )
}

export default TermsandConditions