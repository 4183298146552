import React from 'react';
import "../style/Home.css"
import { Card, CardBody, CardTitle, Col } from 'react-bootstrap';

const Animation = ({ id }) => {
    return (
        <div className='animation-bg' id={id}>
            <Col lg={5} md={5} sm={12} xs={12} className='animation-card-col'>
                <Card className='animation-card'>
                    <CardTitle>
                        <h1 className='title1'>
                            <p className='doorpe-starts'>DoorPe Starts from</p>
                            <p className='hi-neighbour'>Hi,Neighbour !</p>
                        </h1>
                    </CardTitle>
                    <CardBody>
                        <p className='stronger-connections'>
                            DoorPe is your go-to platform for creating<br></br> stronger connections within your<br></br> neighbourhood. Whether you’re a new arrival<br></br> or a long-time local, DoorPe helps you engage<br></br> with neighbours, local businesses, and home-<br></br>based entrepreneurs, making it easy to form <br></br>lasting relationships.<br></br> </p>
                        <p className='stronger-connections'>The platform creates genuine local<br></br> experiences where every interaction helps<br></br> strengthen relationships, making your<br></br> neighbourhood feel more welcoming and<br></br> inclusive.</p>
                    </CardBody>
                </Card>
            </Col>
            <div className='message'>
            <Col lg={4} md={4} sm={6} xs={6} className='text-box-col'>
                <img src="./images/Hello_text.png" alt="Hello-text-box" className='hello-text-box' />
            </Col>
            <Col lg={3} md={3} sm={6} xs={6} className='person-col'>
                <img src='./images/Person.png' className='person-img' alt="hello-neighbour" />
            </Col>
            </div>
        </div>
    )
}

export default Animation