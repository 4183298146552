import React from 'react'
import "../style/Home.css"
import { useLottie } from "lottie-react";
import animationData2 from "../images/local-brands.json";
import { Row, Col } from 'react-bootstrap';
import animationData3 from "../images/Neighbour.json"
import animationData4 from "../images/strengthen_local_bonds.json"

const Reasons = () => {
  const options1 = {
    animationData: animationData2,
    loop: true,
    style: {
      width: '75%', height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  };
  const options2 = {
    animationData: animationData3,
    loop: true,
    style: {
      width: '75%', height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  };
  const options3 = {
    animationData: animationData4,
    loop: true,
    style: {
      width: '60%', height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  };
  const { View: View1 } = useLottie(options1);
  const { View: View2 } = useLottie(options2);
  const { View: View3 } = useLottie(options3);
  return (
    <div className='reasons-bg'>
      <>
        <section className='container'>
          <h1 className='worthy-reasons'>3 worthy reasons </h1>
          <h1 className='choose-doorpe'>to choose doorpe</h1>
          <Row>
            <Col lg={4} xl={4} md={6} sm={12} xs={12} className='local-bonds'>
              <div>
                {View1}
                <p className='strengthen'>
                  Find awesome<br></br> neighbors like you
                </p>
              </div>
            </Col>
            <Col lg={4} xl={4} md={6} sm={12} xs={12} className='local-bonds'>
              <div >
                {View2}
                <p className='strengthen'>
                  A neighbour in need is<br></br> a neighbour indeed
                </p>
              </div>
            </Col>
            <Col lg={4} xl={4} md={6} sm={12} xs={12} className='local-bonds'>
              <div>
                {View3}
                <p className='strengthen'>
                  Strengthen your<br></br> local bonds
                </p>
              </div>
            </Col>
          </Row>
        </section>
        <section className='check-neighbours'>
          <img src="./images/Neighbours2.png" className='Neighbours-img' loading="lazy" alt="" />
        </section>
      </>
    </div>
  )
}

export default Reasons