import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import Privacypolicy from "./screens/Privacypolicy";
import TermsandConditions from "./screens/TermsandConditions";
import Redirection from "./screens/Redirection";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/contact" element={<Contact/>} />
        <Route path="/privacy-policy.html" element={<Privacypolicy />}></Route>
        <Route
          path="/terms-of-use.html"
          element={<TermsandConditions />}
        ></Route>
        <Route path="/redirect/*" element={<Redirection />}></Route>
      </Routes>
    </div>
  );
}

export default App;
