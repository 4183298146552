import React, { useEffect, useState, useRef } from 'react';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';
import "../style/Home.css";

const flickityOptions = {
  wrapAround: false,
  autoPlay: 3000,
  pauseAutoPlayOnHover: false,
  fade: false,
  prevNextButtons: false,
  pageDots: true,
  initialIndex: 0,
  groupCells: 1,
  cellAlign: 'center',
};

const HomePage = ({ setSelectedIndex, selectedIndex, id }) => {
  const [autoPlay, setAutoPlay] = useState(3000);
  const videoRef = useRef(null);


  const updateDotStyles = (index) => {
    console.log(index,selectedIndex)
    const dots = document.querySelectorAll('.flickity-page-dots .dot');
    if(selectedIndex === 1)
    {
      dots.background='green'
    }
    dots.forEach((dot, i) => {
      

      dot.style.width = '14px';
      dot.style.height = '14px';
      if (i === index) {
        dot.style.width = '32px';
        dot.style.height = '32px';
      } else if (i === (index - 1 + dots.length) % dots.length) {
        dot.style.width = '25px';
        dot.style.height = '25px';
      } else if (i === (index - 2 + dots.length) % dots.length) {
        dot.style.width = '14px';
        dot.style.height = '14px';
      } else if (i === (index - 3 + dots.length) % dots.length) {
        dot.style.width = '8px';
        dot.style.height = '8px';
      }
   
    });
  };

  useEffect(() => {
    updateDotStyles(selectedIndex);
    if (selectedIndex === 3) {
      setAutoPlay(false);
    } else {
      setAutoPlay(3000);
    }
    if (videoRef.current) {
      if (selectedIndex === 1) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [selectedIndex]);
  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const tiltX = (clientY / windowHeight - 0.5) * 65;
      const tiltY = (clientX / windowWidth - 0.5) * -65;
      const images = document.querySelectorAll('.carousel-cell img');
      images.forEach((image) => {
        image.style.transform = `rotateX(${tiltX}deg) rotateY(${tiltY}deg)`;
      });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <section className="doorpe-intro" style={{
      background: selectedIndex === 0
        ? 'linear-gradient(119deg, #DECCE7, #F0BABD)'
        : selectedIndex === 1
          ? 'linear-gradient(119deg, #984FC9, #CEA7E6)'
          : selectedIndex === 2
            ? 'linear-gradient(119deg, #EDB9BD, #F0D5BA)'
            : 'linear-gradient(119deg, #D8D2F7, #A598F0)'
          }}>
      <div className="overflow-hidden">
        <div>
          <section className='slider-section'>
            <Flickity options={{
              ...flickityOptions,
              autoPlay: autoPlay,
              on: {
                change: (index) => {
                  setSelectedIndex(index);
                  updateDotStyles(index);
                },
              },
            }}>
              {/* Slide 1 */}
              <div
                className="carousel-cell d-flex justify-content-center align-items-center"
                style={{
                  
                  width: selectedIndex === 0 ? '60%' : '40%',
                  marginTop: selectedIndex === 0 ? '' : '4%',
                }}
              >
                <div className="carousel-content text-center">
                  <img
                    className="hello-beighboors"
                    src="./images/HomeImage2.png"
                    alt="First slide"
                    loading="lazy"
                    style={{
                      width: selectedIndex === 0 ? '90%' : '54%',
                      transform: selectedIndex === 0 ? 'rotate(0deg)' : 'rotate(10deg)',
                    }}
                  />
                  <h1 className={`welcome-text ${selectedIndex === 0 ? 'visible' : ''}`}>
                    Welcome to the local conversations!
                  </h1>
                </div>
              </div>

              {/* Slide 2 */}
              <div
                className="carousel-cell d-flex justify-content-center align-items-center"
                style={{
                  width: selectedIndex === 1 ? '60%' : '40%',
                  marginTop: selectedIndex === 1 ? '' : '4%',
                }}
              >
                <div className="carousel-content text-center">
                  <video
                    ref={videoRef}
                    // width="640"
                    // height="460"
                    loop
                    muted
                    className="welcome-video"
                  >
                    <source src="./images/Business.webm" type="video/webm" />
                  </video>
                  <h1 className={`welcome-text2 ${selectedIndex === 1 ? 'visible' : ''}`}>
                    Discover and discuss<br /> what truly matters in your neighbourhood
                  </h1>
                </div>
              </div>

              {/* Slide 3 */}
              <div
                className="carousel-cell d-flex justify-content-center align-items-center"
                style={{
                  width: selectedIndex === 2 ? '67%' : '45%',
                  marginTop: selectedIndex === 2 ? '' : '2%',
                }}
              >
                <div className="carousel-content text-center">
                  <img
                    className="party-img"
                    src="./images/PartyImage2.png"
                    alt="Third slide"
                    loading="lazy"
                    style={{ width: selectedIndex === 2 ? '55vw' : '54%' }}
                  />
                  <h1 className={`welcome-text3 ${selectedIndex === 2 ? 'visible' : ''}`}>
                    Seeb winter party barbeque
                  </h1>
                </div>
              </div>

              {/* Slide 4 */}
              <div
                className="carousel-cell d-flex justify-content-center align-items-center slide4"
                style={{
                  width: selectedIndex === 3 ? '70%' : '30%',
                  marginTop: selectedIndex === 3 ? '1%' : '4%',
                }}
              >
                <div className="carousel-content text-center">
                  <img
                    className="women-img"
                    src="./images/WomenImage2.png"
                    alt="Fourth slide"
                    loading="lazy"
                    style={{ width: selectedIndex === 3 ? '50%' : '36%' }}
                  />
                  <h1 className={`welcome-text4 ${selectedIndex === 3 ? 'visible' : ''}`}>
                    Feel the good vibes<br />knowing you’ve made a difference
                  </h1>
                </div>
              </div>
            </Flickity>
          </section>
        </div>
      </div>
    </section>
  );
};

export default HomePage;
