import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../style/Home.css";
import HomePage from './HomePage';
import LaunchPage from './LaunchPage';
import Animation from './Animation';
import Services from './Services';
import Reasons from './Reasons';
import Footer from './Footer';

const Header = () => {
  const [navbarColor, setNavbarColor] = useState('black');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isScrolledBeyond1200, setIsScrolledBeyond1200] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsScrolledBeyond1200(scrollY > 600);

      if (scrollY === 0) {
        setNavbarColor('black');
      } else if (scrollY > 300 && scrollY <= 600) {
        setNavbarColor('scrolled');
      } else if (scrollY > 600) {
        setNavbarColor('white');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`Element with id "${id}" not found.`);
    }
  };

  return (
    <div>
      <section className="navbar-fields overflow-hidden p-0">
        <div className="container">
          <Navbar
            fixed="top"
            expand="lg"
            className={`navbar ${navbarColor}`}
            style={{
              background:
                navbarColor === 'scrolled'
                  ? selectedIndex === 0
                    ? 'linear-gradient(119deg, #DECCE7, #F0BABD)'
                    : selectedIndex === 1
                    ? 'linear-gradient(119deg, #984FC9, #CEA7E6)'
                    : selectedIndex === 2
                    ? 'linear-gradient(119deg, #EDB9BD, #F0D5BA)'
                    : 'linear-gradient(119deg, #F5E0FF, #FFFFFF)'

                  : navbarColor === 'white'
                  ? 'white'
                  : ''
            }}
          >
            <Container>
              <Navbar.Brand href="#home">
  <img
    src={
      isScrolledBeyond1200
        ? './images/Doorpe_Logo.png'
        : './images/doorpe_white_logo.png'
    }
    className={
      isScrolledBeyond1200
        ? 'doorpe-black-logo'
        : 'doorpe-white-logo'
    }
    alt="Logo"
  />
</Navbar.Brand>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="w-100 navbaritem2">
                  <div className="doorpe-icon">
                    <img
                      src="./images/doorpe_center_logo.png"
                      className="doorpe-logo"
                      alt="Center Logo"
                    />
                  </div>
                  <div className="right-nav-item">
                    <Nav.Link
                      className={`nav-item-color nav-item1 business-link ${
                        isScrolledBeyond1200 ? 'text-black' : ''
                      }`}
                      onClick={() => scrollToSection('animation-section')}
                      style={{
                        color: isScrolledBeyond1200 ? 'black' : 'white',
                        transition: 'color 0.3s ease',
                      }}
                    >
                      neighbours
                    </Nav.Link>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <HomePage selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
        <Animation id="animation-section" />
        <Services />
        <Reasons />
        <Footer />
      </section>
    </div>
  );
};

export default Header;
