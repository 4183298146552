import React from 'react'
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from 'react-router-dom';
import "../style/Home.css"

const Privacypolicy = () => {
  const button5 = useNavigate()
  const navigate5 = () => {
    button5('/')
  }

  return (
    <section style={{ backgroundColor: "white" }}>
      <Container fluid className="p-5">
        <img src="./images/backbutton.png" alt="" className='arrow-img' onClick={navigate5}></img>
        <h1 style={{ color: "black", marginTop: "1%" }} className='privacypolicy2'>Privacy Policy</h1>
        <p style={{ marginTop: "30px", color: "black" }} className='policy-para'>This Privacy Policy is updated on December 1, 2024.</p>
        <p style={{ marginTop: "30px", color: "black" }} className='encourage' >Welcome to ‘DoorPe’! DoorPe is your go-to platform for creating stronger connections within your neighbourhood. Whether you’re a new arrival or a long-time local, DoorPe helps you engage with neighbours, local businesses, and home-based entrepreneurs, making it easy to form lasting relationships.</p>
        <p style={{ marginTop: "5px", color: "black" }} className='encourage' >We invite you to read this Privacy Policy (“Privacy Policy”), which explains our privacy practices and how we handle personal information collected through DoorPe, our marketing activities, our live events, from third party sources, and other activities described in this Privacy Policy. This Privacy Policy also explains how we use and share such information and choices you have about your personal information.</p>
        <p style={{ marginTop: "5px", color: "black" }} className='encourage' >We use “DoorPe” to refer to the Door.pe websites and our iOS and Android apps. Those websites and apps, and any other services we offer, are referred to as our “Services.” We also use “DoorPe” “we,” “us,” and “our” to refer to the companies operating our Services.</p>
        <p style={{ marginTop: "5px", color: "black" }} className='encourage' >Through the utilization of our Services, you express your consent to abide by the provisions outlined in this Privacy Policy. Should you decline to accept these terms, you are advised to discontinue your use of the Service, or any associated Mobile App, Website, or Service derived from it.</p>
        <p style={{ marginTop: "5px", color: "black" }} className='encourage' >Unless indicated otherwise, this privacy policy (“Policy”) applies to all our website, domains, apps, products, services and features (the “Services”). We believe you should always know what data we collect from you and how we use it, and that you should have meaningful control over both. Although some information is required in order to use DoorPe and for us to provide the Services. </p>
        <p style={{ marginTop: "5px", color: "black" }} className='encourage' >These terms of use do not apply to commercial users (merchants, freelancers and other entrepreneurs) and organizations that demonstrate a non-profit, non-commercial and non-profit-making activity. You can register with “Door.pe” using a so-called business profile and are subject to the <span style={{ textDecoration: 'underline' }}>terms of use for commercial users.</span></p>
        <p style={{ marginTop: "5px", color: "black" }} className='encourage' >We use this information for the purposes that are described further below: </p>
        <Accordion className='rounded-2 ' >
          <Accordion.Item eventKey="0" className='mt-5'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'>Information collection, use and sharing</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <div>
                <h4 className='info1'>Information you provide to us</h4>
                <p className='collect1'>We collect information you provide directly to us when you create an account, participate in any interactive features of the application, fill out a form, participate in a contest or promotion, make a purchase, apply for a job, communicate with us via third party social media sites, request customer support or otherwise communicate with us. The types of information we may collect include:</p>
                <ul>
                  <li>
                    <p className='collect1 mt-0'>Your name, email address, geographical location, postal address, phone number, financial information (including credit or debit card number, expiration date and security code) and any other information you choose to provide.</p>
                  </li>
                  <li>
                    <p className='collect1 mt-0'>IP address of the device you used to access the App, as well as your visit time and date.</p>
                  </li>    <li>
                    <p className='collect1 mt-0'>Device data which tells us about the device you’re using: what type and model it is, its operating system and version, its unique identifier, crash data (if applicable), and what settings you’ve enabled. Your device and software settings affect what information your device makes available to us.</p>
                  </li>    <li>
                    <p className='collect1 mt-0'>Log data which is automatically recorded by our servers based on data sent by your app or browser. This includes the date and time you visited, the browser or app version you used (and its settings), the URLs you came from and go to, your IP address and protocol, and how you used DoorPe.</p>
                  </li>
                </ul>
                <h4 className='info1'>Use of Information </h4>
                <p className='collect1'>We may use information about you for various purposes, including but not limited to: maintaining and improving services, deliver the services you request, process transactions, respond to comments, questions and provide customer service, communicate, monitor and analyse trends.</p>
                <p className='collect1'>We use this information for things like keeping your account secure as well as showing us what features you use most to help us optimize the platform for user satisfaction.</p>
                <p className='collect1'>The above information is collected for the improvement of the App performance. Such information is not disclosed at all to any other party unless required by law or for the protection of the rights and use of DoorPe.</p>
                <p className='collect1'>Your personal information will only be made available to DoorPe employees who need to have access to it. This information will not be made publicly available without your consent. Similarly, no such information will be exchanged, sold, or traded with any third party without your prior consent, and access to that information will only be permitted to qualified individuals and specialists who provide the DoorPe services related to your interactions with our Website/App.</p>
                <p className='collect1'>​In order to enable us to operate and provide the Services, we use information to get you started on DoorPe.</p>
                <ul>
                  <li>
                    <p className='collect1 mt-0'>To help you publish Content and view and respond to content from other members, based on your posting selections.</p>
                  </li>
                  <li>
                    <p className='collect1 mt-0'>To highlight popular neighbourhood topics or resources on a neighbourhood’s public home page or an organisation’s public page.</p>
                  </li>
                  <li>
                    <p className='collect1 mt-0'>To select top posts in an area and to deliver and tailor your newsfeed.</p>
                  </li>
                  <li>
                    <p className='collect1 mt-0'>To help support businesses recommended by DoorPe neighbours. </p>
                  </li>
                  <li>
                    <p className='collect1 mt-0'>To respond to your support requests and comments.</p>
                  </li>
                  <li>
                    <p className='collect1 mt-0'>To test and evaluate potential new products and features on DoorPe.</p>
                  </li>
                  <li>
                    <p className='collect1 mt-0'>To diagnose and resolve problems, analyse trends, and monitor aggregate usage and resource needs.</p>
                  </li>
                  <li>
                    <p className='collect1 mt-0'>For our own marketing activities, such as carrying out analysis to create statistics on and insights into the use of our Services.  </p>
                  </li>
                  <li>
                    <p className='collect1 mt-0'>If you want to allow us to show you where you are on the map, tag your posts, photos and events by location, or verify your address, you can enable sharing this in your settings.</p>
                  </li>
                </ul>
              </div>
              <div>
                <h4 className='info1'>Sharing of Information</h4>
                <p className='collect1'>We may share information about you as follows or as otherwise with vendors, partners and other service providers who need access to carry out work on our behalf.  We may share this information with government agencies assisting us for crime prevention or investigation. We may disclose your personal information if we are required by law to do so or if you violate our terms and conditions</p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  >Protection of your personal information</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p>Our website and mobile application has secure pages to collect user information and most critical data is stored in encrypted form. We use various technical and management practices to help protect the confidentiality, security and integrity of data stored on our system. Our servers use Secure Sockets Layer (SSL) and encryption technology that works with Netscape Navigator, Microsoft Internet Explorer, Firefox, Safari and Chrome.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  >Payments        </h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>When you place an order through DoorPe, you are required to select a method of payment. DoorPe is not interested in your debit/credit card information nor does it store any of your debit/credit information. For transactions with online debit/credit cards, we transmit your entire card information to the appropriate debit/credit card company in an encrypted format with globally accepted rules and applications during order processing. Upon your choice, we keep a part of your card information in an encrypted format, taking precaution to maintain physical, electronic and procedural safeguards over your credit card information.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header' >Marketing and Survey</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>We value opinions and comments from our Users and frequently conduct surveys, online, on the Application. Participation in these surveys is entirely optional. Typically, the information received is aggregated, and used to make improvements to website, application, other sales channels, services and to develop appealing content, features and promotions for members based on the results of the surveys. We don't sell or rent your personal information to third parties for their marketing purposes without your explicit consent. We may combine your information with information we collect from other companies and use it to improve and personalise our services, content and advertising. If you do not want to receive marketing communication, simply communicate your preference to us via e-mail or change your relevant settings from my account link on the user main page..</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'   >How to delete your account</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>At DoorPe, we provide you with the option to delete your account using our mobile application. Please note that if you have any pending transactions or outstanding amounts due, you will not be able to delete your account. Please complete all pending transactions and/or payments to delete your account. To delete your your account using our mobile application, please follow these steps:</p>
              <ol>
                <li>
                  Open the DoorPe mobile app on your device.
                </li>
                <li>
                  Navigate to the 'Profile' menu.
                </li>
                <li>
                  Select 'Help and Support.'
                </li>
                <li>
                  Raise a ticket.
                </li>
                <li>
                  Click on the delete your account option.
                </li>
                <li>
                  Follow the on-screen instructions to confirm and complete the deletion process.
                </li>
              </ol>
              <p className='collect1'>Please note that deleting your account will also delete your personal data, however, we may be required to retain some of your information if we process it as part of our legal obligations, or in pursuit of our own (or a third party’s legitimate interests) such as the assertion of, or defense against, legal claims, preventing fraud or protecting ourselves or others against abusive behavior. Once your data is deleted from the mobile app, it will be permanently removed from our systems and cannot be recovered. If you have any questions, please contact our customer support.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className="privacy-header"   >Cookies</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>DoorPe uses "cookies" to help you personalize your online experience. One of the primary purposes of cookies is to provide a convenience feature to save you time. If you choose to decline cookies, you may not be able to fully experience the interactive features of the DoorPe platform.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className="privacy-header"   >Privacy Policy amendment</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>DoorPe may change this Privacy Policy at any time by publishing the new version on the website.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7" className='mt-2'>
            <Accordion.Header className='header3 rounded-2' ><h3 className="privacy-header"   >Contact us</h3></Accordion.Header>
            <Accordion.Body className="rounded-3 " >
              <p className='collect1'>All comments, queries and requests relating to our use of your information are welcomed and should be addressed to info@door.pe
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  )
}

export default Privacypolicy